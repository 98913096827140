import React from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/img/diginsight.png';

const Header = () => {
  const location = useLocation();

  // Check if the current path is "/dashboards"
  const isDashboardPage = location.pathname === '/dashboards' || location.pathname === '/OneDriveFile' || location.pathname === '/spss';

  return (
    <header className="header" id="header">
      <div className="logo-container">
        <Link to="/dashboards">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>

      {/* Navigation Bar with Multiple Tabs */}
      <nav className="nav-tabs">
        <ul>
          {!isDashboardPage && (
            <>
              {/* <li>
                <NavLink to="/dashboards" 
  className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')}>
                  Dashboard
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/overallshares"
                  className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')}>
                  Overall Shares
                </NavLink>
              </li>
              <li>
                <NavLink to="/secondview"
                  className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')} >
                  Total Shares by Form Factor & In-Person vs. Virtual
                </NavLink>
              </li>
              <li>
                <NavLink to="/paymentmethodviews"
                  className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')} >
                  Payment Method Views
                </NavLink>
              </li>
              <li>
                <NavLink to="/brandshares"
                  className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')} >
                  Brand Share
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/OneDriveFile"
                  className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')}
                >
                  <i className="fa fa-file"></i>
                  <span className="hover-text">Download Files</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/spss"
                  className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')}
                >
                  <i className="fa fa-upload"></i>
                  <span className="hover-text">Upload</span>
                </NavLink>
              </li>
            </>
          )}
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')}
            >
              <i className="fa fa-power-off" style={{ color: 'red' }}></i>
              <span className="hover-text">Log Out</span>
            </NavLink>
          </li>


        </ul>
      </nav>
    </header>
  );
};

export default Header;
