import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import "./SpssPage.css";

function SpssPage() {
  const [variables, setVariables] = useState([]);
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return alert("Please select an SPSS file to upload");

    const formData = new FormData();
    formData.append("spssFile", file);
    setIsLoading(true);

    try {
      const response = await axios.post(`${config.API_BASE_URL}/upload-spss`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const message = typeof response.data === "string"
        ? response.data
        : response.data.message;

      setMessage(message || "File uploaded successfully!");
      alert("File uploaded successfully!");
      setVariables(response.data.variables || []);
      setData(response.data.data || []);
      setFile(null);
      document.querySelector(".file-input").value = "";
    } catch (error) {
      setMessage("File upload failed. Please try again.");
      console.error(error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const goBack = () => {
    navigate("/dashboards");
  };

  return (
    <div className="upload-container">
      <button onClick={goBack} className="back-button">
        &#8592; Back
      </button>
      <h2 className="upload-title">Upload SPSS File</h2>

      <input
        type="file"
        accept=".sav"
        onChange={handleFileChange}
        className="file-input"
      />
      <button
        onClick={handleFileUpload}
        className="filter-button"
        disabled={!file || isLoading}
      >
        {isLoading ? "Uploading..." : "Upload"}
      </button>



      {message && <p style={{ textAlign: "center", color: "#007bff" }}>{message}</p>}

      {variables.length > 0 && (
        <div className="variables-container">
          <h3 className="variables-title">Variables:</h3>
          {variables.map((variable, index) => (
            <span key={index} className="variable-item">
              {variable}
            </span>
          ))}
        </div>
      )}

      {data.length > 0 && (
        <div className="data-table-container">
          <h3 className="data-table-title">Data Table:</h3>
          <table className="data-table">
            <thead>
              <tr>
                {variables.map((variable, index) => (
                  <th key={index} className="table-header">{variable}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex} className="table-row">
                  {variables.map((variable, colIndex) => (
                    <td key={colIndex} className="table-cell">
                      {row[variable]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SpssPage;
