import React, { useEffect, useState, useContext } from 'react';
import './OverallShares.css';
import axios from 'axios';
import { FilterContext } from './FilterContext'; // Import FilterContext
import Filters from './Filters';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import './PaymentMethodViews.css';

const BrandShare = () => {
    const { selectedFilters } = useContext(FilterContext);
    const [tableData, setTableData] = useState([]); // Holds table data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [dataSummary, setDataSummary] = useState(null); // Data summary state
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Debit');

    const navigate = useNavigate(); // Hook for navigation

    // Fetch table data based on selected filters
    useEffect(() => {
        setLoading(true);
        if (Object.keys(selectedFilters).length > 0) {
            axios
                .post(`${config.API_BASE_URL}/api/payment-method-view`, selectedFilters)
                .then((response) => {
                    setTableData(response.data || []);
                    console.log("response", response)
                    // console.log("tableData", tableData)
                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error fetching table data:', err);
                    setError('Failed to load data.');
                    setLoading(false);
                });
        } else {
            // Handle the case when there are no selected filters
            setLoading(false);
            setTableData([]);
        }
    }, [selectedFilters]);

    // Fetch data summary for total transactions

    useEffect(() => {
        if (Object.keys(selectedFilters).length > 0) {
            // Pass the selected filters as where_clause for filtering
            const whereClause = JSON.stringify(selectedFilters);

            fetch(`${config.API_BASE_URL}/api/data-summary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    where_clause: whereClause, // Pass selected filters as the where_clause
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setDataSummary(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data summary:', error);
                    setError('Failed to fetch data summary');
                    setLoading(false);
                });
        } else {
            setDataSummary([]);
        }
    }, [selectedFilters]);




    const cleanPaymentMethodName = (name) => {
        return name
            .replace(/_/g, ' ')
            .replace(/%-|%/g, '')
            .replace(/-Total$/, '')
            .replace(/-Debit$/, '')
            .replace(/-Credit$/, '')
            .replace(/-Interac$/, '')
            .replace(/-Visa$/, '')
    };

    // Filter tableData based on the selected payment method
// Filter tableData based on the selected payment method
const filteredTableData = selectedPaymentMethod === 'All'
    ? tableData
    : tableData.filter((item) => {
        // Always include 'Base' row
        if (item.payment_method.includes('Base')) {
            return true;
        }
        return item.payment_method.includes(selectedPaymentMethod); // Include the selected method
    });

// Extract unique payment methods, ensuring "Base" is always included
const paymentMethods = [
    ...new Set(
        filteredTableData
            .map((item) => item.payment_method)
            .filter((method) => {
                // Exclude "Visa Debit" and "Visa Credit" for Debit and Credit selections
                if (selectedPaymentMethod === 'Debit') {
                    return !/( Debit| Credit)/.test(method); // Exclude Visa Debit
                }
                if (selectedPaymentMethod === 'Credit') {
                    return !/( Debit| Credit)/.test(method); // Exclude Visa Credit
                }
                if (selectedPaymentMethod === 'Total') {
                    return !/(Total )/.test(method); // Exclude Total
                }
                return true; // Include all other methods
            })
    )
];

// Add 'Base' method if it's not already in the list of payment methods
if (!paymentMethods.includes('Base')) {
    paymentMethods.push('Base');
}


    // const paymentMethods = [
    //     ...new Set(  
    //         filteredTableData
    //             .map((item) => item.payment_method)
    //             .filter((method) => !/( Debit| Credit)/.test(method)) 
    //     ),
    // ];
    const years = [...new Set(filteredTableData.map(item => item.year))].sort((a, b) => b - a);

    // Create structuredTableData using years and paymentMethods
    const structuredTableData = years.map(year => {
        return {
            year,
            data: paymentMethods.map(paymentMethod => {
                // Find the corresponding entry for each year and paymentMethod
                const entry = filteredTableData.find(
                    item => item.year === year && item.payment_method === paymentMethod
                );
                // Return the percentage if found, otherwise 0
                // return entry ? entry.percentage : 0;
                return entry
                    ? {
                        percentage: entry.percentage,
                        total_trans_count: entry.total_trans_count || 0,
                    }
                    : { percentage: 0, total_trans_count: 0 };
            })
        };
    });



    const goBack = () => {
        navigate('/dashboards'); // Go back to the previous page/tab
    };

    return (
        <div className="container">
            <Filters pageSpecificFilters="payment" />
            {/* Dropdown for selecting Payment Method */}
            <div className="table-section">
                {/* Back Button */}
                <button onClick={goBack} className="back-button">
                    &#8592;  Back
                </button>
                <div>
                    <div className="dropdown-section">
                        <label htmlFor="paymentMethod">Payment Method View:</label>
                        <select
                            id="paymentMethod"
                            value={selectedPaymentMethod || 'Debit'}  // Ensure selectedPaymentMethod is a valid scalar
                            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                        >
                            {/* <option value="All">All</option> */}
                            <option value="Credit">Credit</option>
                            <option value="Debit">Debit Total</option>
                            <option value="Interac">Interac Debit</option>
                            <option value="Visa">Visa Debit</option>
                            {/* <option value="Total">Total</option> */}
                        </select>
                    </div>
                </div>

                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className="total-transac">
                                    <u>Total Transactions</u>
                                    <br />
                                    <label className="label">
                                        <i>
                                            <strong>Base:</strong> {dataSummary?.trans}
                                        </i>
                                    </label>
                                </th>
                                <th className="share-column">Share of Transactions</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                {loading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : error ? (
                    <p className="error">{error}</p>
                ) : tableData.length === 0 && Object.keys(selectedFilters).length > 0 ? (
                    // Show this message only if no data is returned AND filters are applied
                    <p className="no-data">No data available for the selected filters.</p>
                ) : tableData.length === 0 && Object.keys(selectedFilters).length > 0 ? (
                    // Show a different message if no filters are applied but no data exists
                    <p className="no-data">No data available.</p>
                ) : (
                    <div className='tablesview'>
                        <div>
                            {loading ? (
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            ) : error ? (
                                <p className="error">{error}</p>
                            ) : (
                                <div>{/* Render filtered table */}</div>
                            )}
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>Payment Methods</th>
                                    {years.map(year => (
                                        <th key={year}>{year}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {paymentMethods.map(paymentMethod => {

                                    const isBaseRow = paymentMethod.includes('Base');
                                    const isTotal = paymentMethod.includes('-Total');
                                    return (
                                        <tr key={paymentMethod}
                                            className={
                                                `${isTotal
                                                    ? 'blue-row'
                                                    : (
                                                        paymentMethod.includes('Interac Debit') || paymentMethod.includes('Interac E-transfer')
                                                    )
                                                        ? 'special-row'
                                                        : (
                                                            paymentMethod.includes('Interac In-person') || paymentMethod.includes('Interac Virtual') || paymentMethod.includes('Interac e-Transfer Virtual')
                                                        )
                                                            ? 'gray-row'
                                                            : (
                                                                (paymentMethod.includes('Debit') || paymentMethod.includes('Credit') || paymentMethod.includes('Interac') ||
                                                                    paymentMethod.includes('Visa') || paymentMethod.includes('Mastercard'))
                                                                && (paymentMethod.includes('In-person') || paymentMethod.includes('Virtual')
                                                                    || paymentMethod.includes('Visa Debit') || paymentMethod.includes('Visa Credit'))
                                                            )
                                                                ? 'special-row'
                                                                : (
                                                                    (paymentMethod.includes('Using app or browser') || paymentMethod.includes('Phone-tap')) ||
                                                                    (paymentMethod.includes('Website on mobile device') || paymentMethod.includes('Website on browser'))
                                                                )
                                                                    && (paymentMethod.includes('Debit') || paymentMethod.includes('Credit') || paymentMethod.includes('Interac') || paymentMethod.includes('Visa'))
                                                                    ? 'sub-special-row'
                                                                    : (
                                                                        (paymentMethod.includes('Debit') || paymentMethod.includes('Credit') || paymentMethod.includes('Interac') ||
                                                                            paymentMethod.includes('Interac In-person') || paymentMethod.includes('Interac Virtual') ||
                                                                            paymentMethod.includes('Interac e-Transfer Virtual') || paymentMethod.includes('Total M-Comm') || paymentMethod.includes('Total E-Comm'))
                                                                    )
                                                                        ? 'gray-row'
                                                                        : ''
                                            } ${isBaseRow ? 'base-row' : ''}`}
                                        >
                                            {/* <td>{paymentMethod.replace('-Total', '')}</td> */}
                                            <td>{cleanPaymentMethodName(paymentMethod)}</td>
                                            {/* {structuredTableData.map(row => (
                                                <td key={row.year}>
                                                    {
                                                        // Check if value is valid before calling toFixed
                                                        (() => {
                                                            const value = row.data[paymentMethods.indexOf(paymentMethod)];
                                                            return value != null && !isNaN(value)
                                                                ? `${value.toFixed(1)}%`
                                                                : 'N/A';
                                                        })()
                                                    }
                                                </td>
                                            ))} */}
                                            {structuredTableData.map((row) => {
                                                const rowData = row.data[paymentMethods.indexOf(paymentMethod)];
                                                const totalTransCount = rowData?.total_trans_count;
                                                const percentage = rowData?.percentage;
                                                return (
                                                    <td key={row.year}>
                                                        {isBaseRow
                                                            ? totalTransCount != null && !isNaN(totalTransCount)
                                                                ? `${totalTransCount.toFixed(0)}`
                                                                : 'N/A'
                                                            : percentage != null && !isNaN(percentage)
                                                                ? `${percentage.toFixed(1)}%`
                                                                : 'N/A'}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BrandShare;
