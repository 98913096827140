import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import Login from './components/pages/Login';
import OneDriveFiles from './components/pages/OneDriveFiles';
import SpssPage from './components/pages/SpssPage';
import PaymentTable from './components/pages/OverallShares';
import Header from './components/pages/Header';
import { FilterProvider } from './components/pages/FilterContext';
import SecondView from './components/pages/SecondView';
import LoginButton from './components/pages/LoginButton';
import TabComponent from './components/pages/TabComponent';
import BrandShare from './components/pages/BrandShare';
import PaymentMethodViews from './components/pages/PaymentMethodViews';

function App() {
  const msalConfig = {
    auth: {
      clientId: "b0c8f8f2-bd89-43be-84f7-0913ad504a75",
      authority: "https://login.microsoftonline.com/ef086672-9048-432d-8a11-4c95bf2fef52",
      // redirectUri: "http://localhost:3000",
      redirectUri: "https://interac.edashboard.in",
    },
  };

  const pca = new PublicClientApplication(msalConfig);

  function ConditionalHeader() {
    const location = useLocation();
    return location.pathname !== '/' ? <Header /> : null;
  }
    // Custom component to conditionally render LoginButton
    function ConditionalLoginButton() { 
      const location = useLocation();
      return location.pathname === '/OneDriveFile' ? <LoginButton /> : null;
    }
  

  return (
    <MsalProvider instance={pca}>
      <FilterProvider>
        <Router>
          <div className="App">
            <ConditionalHeader />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/OneDriveFile" element={<OneDriveFiles />} />
              <Route path="/spss" element={<SpssPage />} />
              <Route path="/overallshares" element={<PaymentTable />} />
              <Route path="/secondview" element={<SecondView />} />
              <Route path="/paymentmethodviews" element={<PaymentMethodViews />} />
              <Route path="/brandshares" element={<BrandShare />} />
              <Route path="/dashboards" element={<TabComponent />} />
              {/* Add other routes */}
            </Routes>
          </div>
          <ConditionalLoginButton />
        </Router>
      </FilterProvider>
    </MsalProvider>
  );
}

export default App;
