import React, { useState } from 'react';
import './TabComponent.css';
import transaction from '../../assets/img/Transaction.webp';
import respondent from '../../assets/img/Respondent.webp';

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    {
      id: 1,
      label: 'Transactions',
      image: transaction,
      content: 'Content available for Transactions',
      isRoute: true, // This tab navigates to a route
      route: '/overallshares',
    },
    {
      id: 2,
      label: 'Respondent',
      image: respondent,
      content: 'Content not available for Respondent',
      isRoute: false, // This tab does not navigate
    },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab.id);
    if (tab.isRoute) {
      // Redirect to the route only if `isRoute` is true
      window.location.href = tab.route;
    }
  };

  return (
    <div className="tab-wrapper">
      <div className="tab-box">
        <div className="tab-navigation">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              <img src={tab.image} alt={tab.label} className="tab-image" />
              <div>{tab.label}</div>
            </div>
          ))}
        </div>
        <div className="tab-content">
          <p>{tabs.find((tab) => tab.id === activeTab)?.content}</p>
        </div>
      </div>
    </div>
  );
};

export default TabComponent;
