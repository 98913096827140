import React, { useEffect, useState, useContext } from 'react';
import './OverallShares.css';
import axios from 'axios';
import { FilterContext } from '../../components/pages/FilterContext'; // Import FilterContext
import Filters from './Filters';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

const SecondView = () => {
    const { selectedFilters } = useContext(FilterContext);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('All'); // Default to "All"
    const [selectedTab, setSelectedTab] = useState('All'); // Track selected tab (Debit/Credit)
    const [selectedPaymentMethodBrandSharesData, setSelectedPaymentMethodBrandSharesData] = useState('All'); // Default to "All"
    const [brandSharesData, setBrandSharesData] = useState([]); // Holds table data
    const [dataSummary, setDataSummary] = useState(null); // Data summary state

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        if (Object.keys(selectedFilters).length > 0) {
            axios
                .post(`${config.API_BASE_URL}/api/second-view`, selectedFilters)
                .then((response) => {
                    console.log("response", response);
                    const sortedData = response.data.sort((a, b) => {
                        if (a.payment_method.includes('Base')) return -1;
                        if (b.payment_method.includes('Base')) return 1;
                        if (a.payment_method === 'Other') return 1;  // "Other" goes to the end
                        if (b.payment_method === 'Other') return -1; // "Other" goes to the end
                        return 0; // Keep the rest in the same order
                    });
                    setTableData(sortedData || []);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error fetching table data:', err);
                    setError('Failed to load data.');
                    setLoading(false);
                });
        } else {
            // Handle the case when there are no selected filters
            setLoading(false);
            setTableData([]);
        }
    }, [selectedFilters]);

    useEffect(() => {
        if (Object.keys(selectedFilters).length > 0) {
            // Pass the selected filters as where_clause for filtering
            const whereClause = JSON.stringify(selectedFilters);

            fetch(`${config.API_BASE_URL}/api/data-summary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    where_clause: whereClause, // Pass selected filters as the where_clause
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setDataSummary(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data summary:', error);
                    setError('Failed to fetch data summary');
                    setLoading(false);
                });
        } else {
            setDataSummary([]);
        }
    }, [selectedFilters]);

    // Helper function to clean payment method names
    const cleanPaymentMethodName = (name) => {
        return name
            .replace(/_/g, ' ')
            .replace(/%-|%/g, '')
            .replace(/-Total$/, '')
            .replace(/ - Debit$/, '')
            .replace(/ - Credit$/, '')
            .replace(/ - interac e-transfer$/, '')
            .replace(/ReloadablePrePaid/, 'Reloadable/Pre-Paid');
    };

    // Filter payment methods based on selected tab
    const filteredTableData = tableData.filter(item => {
        if (selectedTab === 'Debit' && !/Debit_\w+/.test(item.payment_method)) {
            return item.payment_method.includes('Debit');
        }
        if (selectedTab === 'Credit' && !/Credit\s.*/.test(item.payment_method)) {
            return item.payment_method.includes('Credit');
        }
        return true; // For 'All' option
    });
    // Extract unique payment methods for the filtered data
    // Extract and clean unique payment methods
    const paymentMethods = [
        ...new Set(
            filteredTableData
                .map((item) => item.payment_method)
                .filter((method) => !/(Debit_|Credit_)/.test(method)) // Exclude 'Debit_' or 'Credit_'
        ),
    ];

    // Extract unique years
    // Extract unique years and sort them in descending order
    const years = [...new Set(filteredTableData.map((item) => item.trans_year))].sort((a, b) => b - a);


    // Structure table data for the filtered data
    const structuredTableData = years.map((year) => ({
        year,
        data: paymentMethods.map((method) => {
            const entry = filteredTableData.find(
                (item) => item.trans_year === year && item.payment_method === method
            );
            return entry ? entry.percentage : 0;
        }),
    }));

    const goBack = () => {
        navigate('/dashboards');
    };

    return (
        <div className="container">
            <Filters pageSpecificFilters="payment" />
            <div className="table-section">
                <button onClick={goBack} className="back-button">
                    &#8592;  Back
                </button>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className="total-transac">
                                    <u>Total Transactions</u>
                                    <br />
                                    <label className="label">
                                        <i>
                                            <strong>Base:</strong>  {dataSummary?.trans}{/* Replace with actual data summary if needed */}
                                        </i>
                                    </label>
                                </th>
                                <th className="share-column">Share of Transactions</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                {loading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : error ? (
                    <p className="error">{error}</p>
                ) : tableData.length === 0 && Object.keys(selectedFilters).length > 0 ? (
                    <p className="no-data">No data available for the selected filters.</p>
                ) : tableData.length === 0 && Object.keys(selectedFilters).length > 0 ? (
                    <p className="no-data">No data available.</p>
                ) : (
                    <div className="tablesview">
                        <table>
                            <thead>
                                <tr>
                                    <th>Payment Methods</th>
                                    {years.map((year) => (
                                        <th key={year}>{year}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {paymentMethods.map((method) => {
                                    const isBaseRow = method.includes('Base');
                                    const isTotal = method.includes('Total');
                                    return (
                                        <tr
                                            key={method}
                                            className={`${isTotal
                                                ? 'blue-row'
                                                : (method.includes('Debit') || method.includes('Credit') || method.includes('interac e-transfer')) &&
                                                    (method.includes('InPerson') || method.includes('Virtual'))
                                                    ? 'special-row'
                                                    : (method.includes('Using app or browser') || method.includes('Phone-tap')) &&
                                                        (method.includes('Debit') || method.includes('Credit'))
                                                        ? 'sub-special-row'
                                                        : (method.includes('Debit') || method.includes('Credit') || method.includes('interac e-transfer'))
                                                            ? 'gray-row'
                                                            : ''
                                                } ${isBaseRow ? 'base-row' : ''}`}
                                        >
                                            <td>{cleanPaymentMethodName(method)}</td>
                                            {structuredTableData.map((row) => {
                                                const value = row.data[paymentMethods.indexOf(method)];
                                                const isBase = method === "Base"; // Check if the payment method is "Base"

                                                return (
                                                    <td key={row.year}>
                                                        {value != null && !isNaN(value)
                                                            ? isBase
                                                                ? `${value.toFixed(0)}` // No percentage formatting for "Base"
                                                                : `${value.toFixed(1)}%` // Percentage formatting for other methods
                                                            : "N/A"}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SecondView;
