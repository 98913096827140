import React, { useEffect, useContext, useState } from 'react';
import { FilterContext } from './FilterContext';
import axios from 'axios';
import config from '../../config';
import Select from 'react-select';

const Filters = ({ pageSpecificFilters }) => {
  const { filters, setFilters, selectedFilters, updateFilters, resetFilters } = useContext(FilterContext);
  const [filteredOptions, setFilteredOptions] = useState({});

  useEffect(() => {
    if (!filters[pageSpecificFilters]) {
      axios
        .get(`${config.API_BASE_URL}/api/filters`, {
          params: { filterType: pageSpecificFilters },
        })
        .then((response) => {
          const filterData = response.data;

          console.log('Fetched Filters:', filterData); // Log the entire filter data structure

          // Initialize filters in context
          setFilters((prev) => ({
            ...prev,
            [pageSpecificFilters]: filterData,
          }));

          // Update selectedFilters with pre-selected values
          Object.values(filterData).forEach((group) => {
            // console.log(`Processing group:`, group); // Log group info
            const selectedValues = group.values
              .filter((value) => Boolean(value.selected)) // Ensure selected is treated as boolean
              .map((value) => value.value);

            if (selectedValues.length > 0) {
              const variableName = group.variable_name;
              // console.log(`Updating selected filters for ${variableName}:`, selectedValues); // Log selected values for debugging

              if (variableName) {
                updateFilters(variableName, selectedValues); // Ensure variable_name is used here
              }
            }
          });
        })
        .catch((err) => console.error('Error fetching filters:', err));
    }
  }, [pageSpecificFilters]);

  const handleReset = () => {
    resetFilters(pageSpecificFilters);
    setFilters((prev) => ({ ...prev, [pageSpecificFilters]: {} }));
    window.location.reload();
  };

  const handleFilterChange = (variableName, selectedOptions) => {
    const isMulti = filters[pageSpecificFilters][variableName]?.isMulti;
    let selectedValues = selectedOptions?.map((option) => option.value) || [];

    // Update selectedFilters state
    if (variableName) {
      updateFilters(variableName, selectedValues);
    }

    // Update dependent filters logic
    const updatedFilteredOptions = { ...filteredOptions };
    Object.values(filters[pageSpecificFilters]).forEach((group) => {
      if (group.values.some((value) => value.dependency_id !== null)) {
        updatedFilteredOptions[group.variable_name] = group.values;
      }
    });

    setFilteredOptions(updatedFilteredOptions);
  };

  // Sort filter values based on 'sortOrder' and filter visible values
  const formatOptions = (values) => {
    // console.log('Filter Values:', values);  // Log to verify the values

    return values
      .filter((value) => value.visible === 1) // Only include options where visible === 1
      .sort((a, b) => a.sortOrder - b.sortOrder) // Sort by sortOrder
      .map((value) => {
        const variableName = value.variable_name || 'Default'; // Ensure variable_name fallback is applied correctly
        // console.log(`Formatting value: ${value.label} with variable_name: ${variableName}`); // Log each formatted value

        return {
          label: value.label,
          value: value.value,
          id: value.id,
          variableName: variableName, // Use variable_name or fallback
        };
      });
  };

  const currentFilters = filters[pageSpecificFilters] || {};

  // Sort groups based on 'sortOrder'
  const sortedGroups = Object.values(currentFilters).sort((a, b) => a.sortOrder - b.sortOrder);

  return (
    <div className="filters">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>Filters</h3>
        <button className="filter-button" onClick={handleReset}>
          Reset Filters
        </button>
      </div>

      {Object.keys(currentFilters).length === 0 ? (
        <p>Loading filters...</p>
      ) : (
        sortedGroups.map((group, index) => {
          const variableName = group.variable_name; // Use variable_name to track the filter

          return (
            <div key={group.id || variableName || index} style={{ marginBottom: '4%' }}>
              <label style={{ margin:'2%', display: 'block' }}>
                {group.displayName}:
              </label>
              <Select
                isMulti={group.isMulti}
                options={formatOptions(filteredOptions[variableName] || group.values)}
                value={group.values
                  .filter((value) => value.selected || selectedFilters[variableName]?.includes(value.value))
                  .map((value) => ({
                    label: value.label,
                    value: value.value,
                    variableName: value.variable_name, // Ensure variable_name is included in the selection value
                  }))}
                onChange={(selectedOptions) => handleFilterChange(variableName, selectedOptions)} // Use variable_name here
                closeMenuOnSelect={!group.isMulti}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default Filters;
