import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './OverallShares.css';
import axios from 'axios';
import { FilterContext } from '../../components/pages/FilterContext';
import Filters from './Filters';
import FilterTabs from './FilterTabs';
import config from '../../config';

const PaymentTable = () => {
  const { selectedFilters } = useContext(FilterContext);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uniqueYears, setUniqueYears] = useState([]);
  const [data, setData] = useState(null);
  const [filterTabs, setFilterTabs] = useState({});

  const navigate = useNavigate();

  const handleFilterUpdate = (filterType, filteredValues) => {
    setFilterTabs((prevTabs) => ({
      [filterType]: filteredValues.map((item) => item.value),
    }));
  };

  const filterTabKey = Object.keys(filterTabs)[0];

  useEffect(() => {
    setLoading(true);
    setUniqueYears([]);
    let updatedfilterTabs = filterTabs
    if (filterTabs["trans_year"])
      updatedfilterTabs = {}
    if (Object.keys(selectedFilters).length > 0) {
      axios
        .post(`${config.API_BASE_URL}/api/payment-data`, {
          selectedFilters,
          updatedfilterTabs,
        })
        .then((response) => {
          const data = Array.isArray(response.data) ? response.data : [];
          const sortedData = data.sort((a, b) => {
            if (a.payment === 'Other') return 1;  // "Other" goes to the end
            if (b.payment === 'Other') return -1; // "Other" goes to the end
            return 0; // Keep the rest in the same order
          });

          setTableData(sortedData);
          console.log("Total", data)
          let dynamicPattern = null;

          if (Object.keys(updatedfilterTabs).length === 0) {
            const yearPatternWithSuffix = /_(\d{4}_\d)$/;
            const yearPatternWithoutSuffix = /_(\d{4})$/;

            const hasYearWithSuffix = sortedData.some((row) =>
              Object.keys(row).some((key) => yearPatternWithSuffix.test(key))
            );

            const years = Array.from(
              new Set(
                sortedData.flatMap((row) =>
                  Object.keys(row)
                    .filter((key) => key.startsWith('perc_') || key.startsWith('shares_'))
                    .map((key) =>
                      (hasYearWithSuffix
                        ? key.match(yearPatternWithSuffix)
                        : key.match(yearPatternWithoutSuffix))?.[1]
                    )
                    .filter(Boolean)
                )
              )
            );
            setUniqueYears(years);
          }
          else if (Object.keys(updatedfilterTabs).length > 0 && Object.keys(selectedFilters).length > 0) {
            // console.log("selectedFilter:", updatedfilterTabs);
            const dynamicFilterKey = Object.keys(updatedfilterTabs)[0] || 'user_gender';
            // const genderYearPattern = /_(\w+)_(\d{4})$/;
            const genderYearPattern = new RegExp(
              `${dynamicFilterKey}_(\\w+)_(\\d{4})$`
            );
            const selectedFilter = updatedfilterTabs.selectedFilter || {}; // or another key depending on your structure
            // console.log("selectedFilter:", selectedFilter); // Debugging line

            const extractedColumns = Array.from(
              new Set(
                sortedData.flatMap((row) =>
                  Object.keys(row)
                    .filter((key) => genderYearPattern.test(key) || key.startsWith("perc_"))
                    .map((key) => {
                      const match = key.match(genderYearPattern);
                      return match ? `${match[1]}_${match[2]}` : key;
                    })
                    .filter(Boolean)
                )
              )
            );

            setUniqueYears(extractedColumns);
          }
          else {
            const dynamicFilterKey = Object.keys(updatedfilterTabs)[0] || 'user_gender';
            dynamicPattern = new RegExp(
              `${dynamicFilterKey}_(\\w+)_(\\d{4})_(\\d)`
            );

            const extractedYears = Array.from(
              new Set(
                sortedData.flatMap((row) =>
                  Object.keys(row)
                    .filter((key) => key.startsWith('perc_') || key.startsWith('shares_'))
                    .map((key) => {
                      const match = key.match(dynamicPattern);
                      return match ? `${match[1]}_${match[2]}_${match[3]}` : null;
                    })
                    .filter(Boolean)
                )
              )
            );
            setUniqueYears(extractedYears);
          }
          setError('');

          setLoading(false);

        })
        .catch((err) => {
          console.error('Error fetching table data:', err);
          setError('Failed to load data.');
          setLoading(false);
        });
    } else {
      // Handle the case when there are no selected filters
      setLoading(false);
      setTableData([]);
    }
  }, [selectedFilters, filterTabs]);

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      // Pass the selected filters as where_clause for filtering
      const whereClause = JSON.stringify(selectedFilters);

      fetch(`${config.API_BASE_URL}/api/data-summary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          where_clause: whereClause, // Pass selected filters as the where_clause
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data summary:', error);
          setError('Failed to fetch data summary');
          setLoading(false);
        });
    } else {
      setData([]);
    }
  }, [selectedFilters]);


  const goBack = () => {
    navigate('/dashboards');
  };

  const processedYears = uniqueYears
    .map((year) => ({
      original: year,
      year: parseInt(year.match(/\d{4}/)[0]),
    }))
    .sort((a, b) => b.year - a.year)
    .map((item) => item.original);

  return (
    <div className="container">
      <Filters pageSpecificFilters="payment" />
      <div className="table-section">
        <button onClick={goBack} className="back-button">
          &#8592; Back
        </button>

        <FilterTabs onFilterUpdate={handleFilterUpdate} />

        <div>
          <table>
            <thead>
              <tr>
                <th className="total-transac">
                  <u>Total Transactions</u>
                  <br />
                  <label className="label">
                    <i>
                      <strong>Base:</strong> {data?.trans}
                    </i>
                  </label>
                </th>
                <th className="share-column">Share of Transactions</th>
              </tr>
            </thead>
          </table>
        </div>

        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : error ? (
          <p className="error">{error}</p>
        ) : tableData.length === 0 && Object.keys(selectedFilters).length > 0 ? (
          // Show this message only if no data is returned AND filters are applied
          <p className="no-data">No data available for the selected filters.</p>
        ) : tableData.length === 0 && Object.keys(selectedFilters).length > 0 ? (
          // Show a different message if no filters are applied but no data exists
          <p className="no-data">No data available.</p>
        ) : (
          <div className="tablesview">
            <table>
              {Object.keys(selectedFilters).length > 0 ? (
                <thead>
                  <tr>
                    <th rowSpan="2">Payment Methods</th>
                    {/* Generate Year headers dynamically with colSpan */}
                    {Array.from(new Set(
                      processedYears.map((year) => year.split('_')[year.split('_').length - 1])
                    )).map((year) => {
                      if (year === "0" || year.startsWith("Total")) return null; // Skip if year is "0" or a Total key
                      const count = processedYears.filter((y) => y.endsWith(`_${year}`)).length;
                      return (
                        <th key={year} colSpan={count}>
                          {year}
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    {/* Display the group names, without showing the year */}
                    {processedYears.map((year, index) => (
                      <th key={index}>
                        {year.startsWith("perc")
                          ? "Total" // Label for Total columns
                          : year.split('_').slice(0, -1).join('_')} {/* Remove year part */}
                      </th>
                    ))}
                  </tr>
                </thead>
              ) : (
                <thead>
                  <tr>
                    <th rowSpan="2">Payment Methods</th>
                    {/* Generate Year headers dynamically with colSpan */}
                    {Array.from(new Set(
                      processedYears.map((year) => year.split('_')[year.split('_').length - 2])
                    )).map((year) => {
                      if (year === "0" || year.startsWith("Total")) return null; // Skip if year is "0" or a Total key
                      const count = processedYears.filter((y) => y.endsWith(`_${year}_0`)).length;
                      return (
                        <th key={year} colSpan={count}>
                          {year}
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    {processedYears.map((year, index) => (
                      <th key={index}>
                        {year.startsWith("perc")
                          ? "Total" // Label for Total columns
                          : year.split('_').slice(0, -2).join('_')} {/* Extract the label part */}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {tableData
                  .filter((row) => row.payment !== "") // Filter out rows where payment is an empty string
                  .map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: row.payment === 'Base' ? '#c1c1c1' : 'transparent',
                      }}
                    >
                      <td>
                        {row.payment === "ReloadablePrePaid"
                          ? "Reloadable/Pre-Paid"
                          : row.payment === "E-Transfer"
                            ? "Interac e-Transfer"
                            : row.payment}
                      </td>
                      {processedYears.map((year) => (
                        <td key={`perc_${year}`}>
                          {year.startsWith("perc")
                            ? row[year] !== undefined // Handle Total column separately
                              ? row.payment === 'Base'
                                ? Number(row[year])
                                : Number(row[year]).toFixed(1)
                              : "N/A"
                            : row[`perc_${year}`] !== undefined
                              ? row.payment === 'Base'
                                ? Number(row[`perc_${year}`])
                                : Number(row[`perc_${year}`]).toFixed(1)
                              : row[`perc_${filterTabKey}_${year}`] !== undefined
                                ? row.payment === 'Base'
                                  ? Number(row[`perc_${filterTabKey}_${year}`])
                                  : Number(row[`perc_${filterTabKey}_${year}`]).toFixed(1)
                                : "N/A"}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>

          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentTable;
