import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './FilterTabs.css'; // Import the CSS file
import config from '../../config';

function FilterTabs({ onFilterUpdate }) {
  const [filters, setFilters] = useState({}); // State for filters fetched from the server
  const [activeFilter, setActiveFilter] = useState(null); // Active filter type and value
  const [selectedFilters, setSelectedFilters] = useState({}); // Selected filter values
  const [variableName, setVariableName] = useState(''); // State to store the clicked filter type's variable_name

  useEffect(() => {
    // Fetch filter data on component mount
    axios
      .get(`${config.API_BASE_URL}/api/tabs`)
      .then((response) => {
        const rawFilters = response.data;
        const processedFilters = Object.entries(rawFilters).reduce((acc, [key, values]) => {
          acc[key] = {
            displayName: key,
            values,
          };
          return acc;
        }, {});
        setFilters(processedFilters);
      })
      .catch((error) => console.error('Error fetching filters:', error));
  }, []);

  const handleTabClick = useCallback(
    (filterType, filterValue) => {
      setSelectedFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };

        if (!updatedFilters[filterType]) {
          updatedFilters[filterType] = [];
        }

        // Toggle the selection of the filter value
        if (updatedFilters[filterType].includes(filterValue)) {
          updatedFilters[filterType] = updatedFilters[filterType].filter(
            (value) => value !== filterValue
          );
        } else {
          updatedFilters[filterType].push(filterValue);
        }

        // Update parent with the selected filters and variable_name
        const selectedValues = filters[filterType].values.filter((filter) =>
          updatedFilters[filterType].includes(filter.value)
        );
        const variableName = filters[filterType]?.values[0]?.variable_name || '';
        onFilterUpdate(filterType, selectedValues, variableName);

        return updatedFilters;
      });

      // Update the active filter type
      setActiveFilter(filterType);
    },
    [onFilterUpdate, filters]
  );


  return (
    <div className="filter-tabs-container">
      {Object.keys(filters).map((filterType) => (
        <div key={filterType} className="filter-type-container">
          {/* Render filter type (group) as a button */}
          <button
            className={`filter-button ${activeFilter?.filterType === filterType ? 'active' : ''}`}
            onClick={() => {
              setActiveFilter({ filterType, filterValue: null }); // Set active filter with no value
              // Store the variable_name of the first value
              const variableName = filters[filterType]?.values[0]?.variable_name || '';
              setVariableName(variableName);

              // Send variable_name and all filter values to parent immediately on filter type click
              onFilterUpdate(variableName, filters[filterType]?.values || []);
            }}
          >
            {filters[filterType].displayName}
          </button>


          {/* Render filter values when the filter type is active */}
          {/* {activeFilter?.filterType === filterType && (
            <div className="filter-values">
              {filters[filterType].values.map((filter) => (
                <button
                  key={filter.value}
                  onClick={() => handleTabClick(filterType, filter.value)} // Trigger tab click
                  className={`filter-value-button ${selectedFilters[filterType]?.includes(filter.value) ? 'selected' : ''
                    }`}
                >
                </button>
              ))}
            </div>
          )} */}
        </div>
      ))}
    </div>
  );
}

export default FilterTabs;
